.footer {
    padding: 60px 0px;
}

.footer__wrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 2.5rem;
}

.footer__logo {
    width: 40%;
}

.footer__quicl-links {
    width: 20%;
}

.footer__logo h2 {
    color: var(--primary-color);
}

.small__text {
    font-size: 1rem;
    margin-top: 10px;
}

.quick__links-title {
    font-size: 1.1rem;
    color: var(--primary-color);
    font-weight: 400;
    margin-bottom: 30px;
}

.quick__links {
    list-style: none;
}


.quick__links-item {
    margin-bottom: 30px;
}

.quick__links-item a  {
    font-size: 0.9rem;
    font-weight: 300;
    cursor: pointer;
}

.quick__links-item a:hover {
    color:  var(--p rimary-color);
}

.copyright {
    color: rgb(255, 255, 255, 0.573);
    margin-top: 40px;
    text-align: center;
    font-size: .9rem;
    font-weight: 400;
}

.light-theme .quick__links-item a {
    font-weight: 500;
}

.light-theme .copyright{
     color: #555;
}


@media only screen and (max-width: 768px){
    .footer__logo {
        width: 55%;
    }
    .footer__wrapper {
        flex-wrap: wrap;
        font-size: 1.5rem !important;
        row-gap: 1.5rem;
    }

    .footer__quicl-links {
        margin-top: 30px;
    }

    .footer__logo h2 {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 576px){
    .footer__quicl-links {
        width: 47%;
    }

    .footer__logo {
        width: 47%;
    }
}

.wrapper {
  display: inline-flex;
  list-style: none;
}

.wrapper .icon {
  position: relative;
  background: var(--primary-color);
  border-radius: 50%;
  padding: 15px ;
  margin: 8px;
  width: 40px;
  height: 40px;
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background: #1877F2;
  color: #ffffff;
}

.wrapper .twitter:hover,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip::before {
  background: #2EB943;
  color: #ffffff;
}

.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
  background: #E4405F;
  color: #ffffff;
}

